<template>
  <div>
    <SizeSelector
      @height="setHeight"
      ref="sizeSelector"
      :enable_height="true"
      :enable_width="false"
      :read-only="readOnly"
    ></SizeSelector>
    <RCSCard ref="cardItem" @update="onChildUpdate" :read-only="readOnly"></RCSCard>
    <vs-spacer></vs-spacer>
    <div
      class="error-message height-fit-content my-3 text-center"
      v-if="errorMsg && errorMsg.length"
    >
      <p v-for="(errorObj, index) in errorMsg" :key="index">{{ errorObj }}</p>
    </div>

    <div class="mt-4">
      <BotMessageValidation :read-only="readOnly"/>
    </div>

    <div class="flex justify-center mt-4" v-if="!readOnly">
      <vs-button color="danger" type="flat" @click="onCancel">{{
        lang.botMaker.botEditor.cancelMessage[languageSelected]
      }}</vs-button>

      <vs-button
        :disabled="hasError"
        class="bg-primary-gradient ml-4 add"
        type="filled"
        @click="addMessage()"
        v-if="!botMessageInEdit.isSaved"
      >
        <span
          v-if="!hasError && !botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.addMessage[languageSelected]"
        ></span>

        <span
          v-if="hasError && !botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.addingMessage[languageSelected]"
        ></span>
      </vs-button>

      <vs-button
        :disabled="hasError"
        class="bg-primary-gradient ml-4 modify"
        type="filled"
        @click="modifyMessage()"
        v-if="botMessageInEdit.isSaved"
      >
        <span
          v-if="!hasError && botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.modifyMessage[languageSelected]"
        ></span>
      </vs-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { CardMessageType } from '@/models/Messages/MessageTypes/CardMessageType'
import botMessageValidationMixin from '@/mixins/botMessageValidationMixin'

export default {
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      buttonDisabled: false,
      hasError: false,
      cardHeight: '',
      errorMsg: [],
      cardInfo: {
        header: {
          url: '',
          height: 'MEDIUM'
        },
        title: '',
        subtitle: '',
        options: []
      }
    }
  },
  mixins: [botMessageValidationMixin],
  components: {
    RCSCard: () => import('./RCS/Card'),
    SizeSelector: () => import('./RCS/SizeSelector'),
    BotMessageValidation: () => import('../userSays/BotMessageValidation.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'botMessageInEdit',
      'botMakerMessages',
      'botId',
      'activeChannelView',
      'userMessageInEdit'
    ]),
    uploadMessage() {
      return this.lang.botMaker.botEditor.messageType.RcsCard.addImage[
        this.languageSelected
      ]
    }
  },
  methods: {
    ...mapActions('botMaker', ['SAVE_BOT_MESSAGE', 'GET_DIALOGS']),
    ...mapMutations('botMaker', [
      'SET_ACTIVE_CHANNEL_VIEW',
      'RESET_BOT_MESSAGE',
      'UPDATE_MESSAGE'
    ]),
    onCancel() {
      this.errorMsg = []
      if (this.$refs && this.$refs.cardItem) {
        this.$refs.cardItem.clean()
        this.$refs.sizeSelector.clean()
        this.$refs.cardItem.showPreviewF()
      }
      return true
    },
    successUpload() {
      alert('SUCCESS UPLOAD')
    },
    setHeight(height) {
      this.cardHeight = height
    },
    onChildUpdate(newValue) {
      this.cardInfo = newValue.card
    },
    isError() {
      return false
      /*return (
        this.botMessageInEdit.type.name.length === 0 ||
        this.botMessageInEdit.type.name.length > 2000
      )*/
    },
    gT(item) {
      return this.lang.botMaker.botEditor.messageType.RcsCard.errors[item][
        this.languageSelected
      ]
    },
    async processCard(cardEntry) {
      const card = { ...cardEntry }
      let errorArr = []
      let pictureFile
      const mField = this.lang.botMaker.botEditor.missingField[
        this.languageSelected
      ]
      try {
        if (!card.header.url) {
          pictureFile = Object.assign(card.header.picture)
          if (!pictureFile.fileUrl) {
            await pictureFile.save(this.botId)
          }
          if (!pictureFile.fileUrl)
            errorArr.push(`${mField} ${this.gT('picture').toLowerCase()}`)
        }
      } catch (e) {
        errorArr.push(`${mField} ${this.gT('picture').toLowerCase()}`)
      }

      if (!this.cardHeight) {
        errorArr.push(`${mField} ${this.gT('height').toLowerCase()}`)
      }

      if (!card.title) {
        errorArr.push(`${mField} ${this.gT('text_title').toLowerCase()}`)
      }

      if (!card.subtitle) {
        errorArr.push(`${mField} ${this.gT('text_subtitle').toLowerCase()}`)
      }

      if (errorArr.length) {
        return { error: errorArr }
      }

      if (!card.header.url) card.header.url = pictureFile.fileUrl
      card.header.height = this.cardHeight
      delete card.header.picture
      return card
    },
    async addMessage() {
      this.errorMsg = []
      // ERROR HANDLE TO BE DONE
      this.hasError = this.isError()

      if (this.hasError) {
        return
      }

      this.$vs.loading()
      const getDialogsId =
        this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId
      const dialogSavedId = 
        (this.userMessageInEdit && this.userMessageInEdit.dialogSavedId) ||
        (this.botMessageInEdit && this.botMessageInEdit.dialogSavedId)

      // on update image

      const botDialogHandler = JSON.parse(JSON.stringify(this.botMessageInEdit))
      const botDialog = botDialogHandler.type

      const cardInfo = await this.processCard(this.cardInfo)
      if (cardInfo.error) {
        console.log('ERROR::', cardInfo.error)
        setTimeout(() => {
          this.$vs.loading.close()
          this.errorMsg = cardInfo.error
        }, 2000)
        return false
      }

      this.botMessageInEdit.type = new CardMessageType(
        cardInfo.title + ' ' + cardInfo.subtitle,
        [cardInfo],
        botDialog.platforms,
        botDialog._id,
        botDialog.responseId
      )

      this.botMessageInEdit.type.platforms = ['all']

      await this.saveValidation()

      this.SAVE_BOT_MESSAGE(this.botMessageInEdit)
        // .then(async () => {
        .then(async response => {
          if (this.botMessageInEdit.isSaved) {
            //this.UPDATE_MESSAGE(this.botMessageInEdit)
            // this.RESET_BOT_MESSAGE()
            this.SET_ACTIVE_CHANNEL_VIEW('all')
            this.RESET_BOT_MESSAGE()
            // this.showPreview = true
            // return response
            this.$refs.cardItem.showPreviewF()
          } else {
            await this.GET_DIALOGS(dialogSavedId || getDialogsId)
            // this.RESET_BOT_MESSAGE()
            this.SET_ACTIVE_CHANNEL_VIEW('all')
            return response
          }
        })
        .catch(() => {
          this.$vs.notify({
            title: this.lang.botMaker.errorTitle[this.languageSelected],
            text: this.lang.botMaker.error[this.languageSelected],
            color: 'danger'
          })
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    async modifyMessage() {
      this.errorMsg = []
      console.log('trigger modify ::', this.botMessageInEdit)
      this.hasError = this.isError()

      console.log('ERROR::', this.hasError)

      if (this.hasError) {
        return
      }

      this.$vs.loading()
      // const getDialogsId =
      //   this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId

      const botDialogHandler = JSON.parse(JSON.stringify(this.botMessageInEdit))
      const botDialog = botDialogHandler.type

      const cardInfo = await this.processCard(this.cardInfo)
      if (cardInfo.error) {
        setTimeout(() => {
          this.$vs.loading.close()
          this.errorMsg = cardInfo.error
        }, 2000)
        return false
      }

      this.botMessageInEdit.type = new CardMessageType(
        cardInfo.title + ' ' + cardInfo.subtitle,
        [cardInfo],
        botDialog.platforms,
        botDialog._id,
        botDialog.responseId
      )

      // on update image
      if (this.botMessageInEdit.isSaved) {
        await this.botMessageInEdit.type.save(this.botId)
      }

      this.botMessageInEdit.type.platforms = [this.activeChannelView]

      await this.saveValidation()

      this.SAVE_BOT_MESSAGE(this.botMessageInEdit)
        .then(async () => {
          console.log('add msg ::')
          this.UPDATE_MESSAGE(this.botMessageInEdit)

          this.SET_ACTIVE_CHANNEL_VIEW('all')
          this.$refs.cardItem.showPreviewF()
        })
        .catch(() => {
          this.$vs.notify({
            title: this.lang.botMaker.errorTitle[this.languageSelected],
            text: this.lang.botMaker.error[this.languageSelected],
            color: 'danger'
          })
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    updateCard() {
      if (this.$refs.cardItem) {
        const card = this.botMessageInEdit.type.responseCard[0]
        this.$refs.cardItem.load(card)
        this.$refs.sizeSelector.loadHeight(card.header.height)
        this.cardHeight = card.header.height
        if (card.header.url) {
          this.$refs.cardItem.showPreviewF()
        }
      }
    },
    eraseCard() {
      this.errorMsg = []
      if (this.$refs.cardItem) {
        this.$refs.cardItem.erase()
        this.$refs.sizeSelector.erase()
      }
    }
  },
  watch: {
    '$store.state.botMaker.botMessageInEdit.id'() {
      this.eraseCard()
      if (this.botMessageInEdit.type.isCardMessage()) {
        this.updateCard()
      } else {
        this.eraseCard()
      }
    }
  },
  mounted() {
    if (this.botMessageInEdit.type.isCardMessage()) {
      this.updateCard()
    } else {
      this.eraseCard()
    }
  }
}
</script>

<style lang="scss">
.rcs-card {
  .con-input-upload {
    border-radius: 0;
    height: 150px;
  }
  .select-box {
    background-color: white;
    //height: 200px;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .con-input-upload.disabled-upload {
    display: none !important;
  }
  .img-upload {
    width: 100%;
    margin: auto;
    height: 150px;
  }
}
.content-rcs {
  max-width: 360px;
  margin: auto;
  .vs-con-input {
    width: 60%;
  }
  .vs-con-input-label.input-rcs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    &.title {
      .vs-input--label {
        font-size: 1.1rem;
        font-weight: bold;
      }
    }
    &.subtitle {
      padding-bottom: 15px;
    }
    .vs-input--label {
      width: 30%;
      height: fit-content;
      margin-top: auto;
      margin-bottom: auto;
      color: #737373;
    }
  }
  .text-content {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #f1f1f1;
  }
  background-color: #e2e3e4;
  border-radius: 20px;
  padding: 2px;
  padding-top: 0;
  overflow: hidden;
  width: 100%;
  .btn-upload-all {
    display: none;
  }
  .con-input-upload .text-input {
    color: #d0d2d3;
    &:before {
      content: '+';
      text-align: center;
      display: block;
      width: 100%;
      font-size: 20px;
    }
  }
  .con-img-upload {
    .img-upload {
      box-shadow: none;
      background: transparent;
    }
    box-shadow: none;
    background: transparent;
  }
  .con-input-upload {
    background-color: transparent;
    border: none;
  }
}

.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}

.height-fit-content {
  height: fit-content !important;
}
</style>
